<template>
  <div>
    <Header />
    <section class="section1">
      <div class="text-box">
        <div class="h4">客多多CRM，为您提供优质的服务</div>
        <div class="desc mb-1">
          全方位客户分析，精准营销，助力商家提升客户资产价值
        </div>
        <div class="desc mb-1">
          CRM会员营销 / 短信营销 / 短信群发 / 购物车营销 / 优惠券
        </div>
        <div class="desc mb-1">
          营销效果分析 / 订单关怀 / 客户关系管理 / 订单催付催评
        </div>
        <div class="desc mb-1">
          物流提醒 / 评价分析 / 中差评管理 / 人群画像分析
        </div>
        <div class="desc mb-1">
          对接官方运营平台 / 短链接分析 / 打折促销
        </div>
      </div>

    </section>

    <section class="section2">
      <div class="container container-space-y-l clearfix">
        <ul class="d-flex justify-content-between">
          <li>
            <img style="width: 80px;"
                 src="@/assets/images/illustration_2_1.png">
            <div class="h4">订单关怀</div>
            <div class="h6">自动化关怀，全流程跟踪</div>
          </li>
          <li>
            <img style="width: 80px;"
                 src="@/assets/images/illustration_2_2.png">
            <div class="h4">会员管理</div>
            <div class="h6">个性化标签、高级分组、客户列表</div>
          </li>
          <li>
            <img style="width: 80px;"
                 src="@/assets/images/illustration_2_3.png">
            <div class="h4">精准营销</div>
            <div class="h6">多种营销方式智能组合</div>
          </li>
          <li>
            <img style="width: 80px;"
                 src="@/assets/images/illustration_2_4.png">
            <div class="h4">数据分析</div>
            <div class="h6">会员、订单、商品多维度分析</div>
          </li>
          <li>
            <img style="width: 80px;"
                 src="@/assets/images/illustration_2_5.png">
            <div class="h4">促销 ● 优惠券</div>
            <div class="h6">设置折扣、配置优惠券</div>
          </li>
        </ul>
      </div>
    </section>

    <BannerRegistration />
    <Footer />
  </div>
</template>

<script>

import Header from '@/components/header'
import Footer from '@/components/footer'
import BannerRegistration from '@/components/banner-registration'
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    BannerRegistration
  }
}

</script>

<style lang="scss" scoped>
.section1 {
  position: relative;
  width: 100%;
  height: 450px;
  background: url("~@/assets/images/banner01.jpg");
  background-size: 100%;
  background-position: center;
  color: #fff;

  .text-box {
    position: absolute;
    left: 16%;
    top: 100px;

    .desc {
      color: #c5c5c5;
    }
  }
}

.section2 {
  position: relative;

  .funs {
    > li {
      width: 100px;
      background: red;
    }
  }

  .card {
    width: 800px;
    padding-top: 20px;
    padding-left: 20px;
    background: #f8f9fa;
    border-radius: 8px;

    float: right;

    img {
      width: 100%;
      box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
